.navbar-brand,
.navbar-brand small {
  color: #fff !important;
  font-family: AileronBold !important;
  text-decoration: none;
  font-size: x-large;
  font-display: swap;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.navbar {
  background: #f16d6c;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
