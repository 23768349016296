@font-face {
  font-family: "Aileron";
  src: local("Aileron"),
    url(./fonts/aileron-regular-webfont.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "AileronBold";
  src: local("AileronBold"),
    url(./fonts/aileron-bold-webfont.woff2) format("woff2");
  font-display: swap;
}

html {
  font-family: "Aileron";
  font-display: swap;
}

/* #E05D5B */

code,
.apicFont,
a.apicFont:hover,
a.apicFont:active {
  color: #f16d6c !important;
}

.alternateFont,
a.alternateFont:hover,
a.alternateFont:active {
  color: #2e3033 !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:hover {
  color: #ffffff;
  background-color: #f16d6c !important;
  border-color: #f16d6c !important;
}
/* 
.card {
  margin-bottom: 5%;
} */
.card-img-top {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}

.empty-card-img-top {
  width: 100%;
  /* height: 75vh; */
  object-fit: cover;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.container {
  margin-bottom: 15vh !important;
  /* padding: 0 !important; */
}
.mdi-36px {
  font-size: 39px;
  margin-right: 10px;
}

.restaurantMessage {
  color: #fff !important;
  text-transform: lowercase;
}

.infiniteScrollContainer {
  min-height: 101vh;
}
